<template>
  <div>
    <v-layout wrap justify-center pa-4 pa-lg-10>
      <v-flex xs12 text-center py-5>
        <span
          class="bold"
          style="
            border-bottom: 5px solid #ff8533;
            font-size: 30px;
            padding-left: 20px;
            padding-right: 20px;
          "
          >Student List</span
        >
      </v-flex>

      <v-flex lg9 style="height: 200px;">
    <v-layout wrap justify-center>
      <v-flex
        v-for="(button, index) in buttons"
        :key="index"
        lg3
        class="d-flex align-center"
      >
        <!-- Conditional rendering for buttons with href -->
        <a
          v-if="button.href"
          :href="button.href"
          target="_blank"
          style="text-decoration: none; color: black"
        >
          <v-btn style="width: 300px;" class="ma-2" color="primary">
            {{ button.label }}
          </v-btn>
        </a>

        <!-- Conditional rendering for buttons that use Vue Router -->
        <v-btn
          v-else
          class="ma-2"
          color="primary"
          @click="handleButtonClick(button.route, button.label)"
        >
          {{ button.label }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-flex>

      <!-- <v-flex xs12 lg9 class="bold" style="font-size:20px;color:#2c3e50" pt-lg-6>
2020-2022 First Year
</v-flex>
  <v-flex x12 lg9>
        <table>
          <tr>
            <th>S.No</th>
            <th>Adm.NO</th>
            <th>Name</th>
            <th>Dob</th>
          </tr>
          <tr>
            <td>1</td>
            <td>2096</td>
            <td>Abilin TA</td>
            <td>08/03/2002</td>
          </tr>
          <tr>
            <td>2</td>
            <td>2097</td>
            <td>Agnus carolin paul</td>
            <td>29/03/1998</td>
          </tr>
          <tr>
            <td>3</td>
            <td>2098</td>
            <td>Ashna maria furtel</td>
            <td>10/03/1999</td>
          </tr>
          <tr>
            <td>4</td>
            <td>2099</td>
            <td>Tresa Joseph</td>
            <td>09/09/1998</td>
          </tr>
           <tr>
            <td>5</td>
            <td>2100</td>
            <td>Athul Joy</td>
            <td>22/12/2002</td>
          </tr>
           <tr>
            <td>6</td>
            <td>2101</td>
            <td>Aparna ES</td>
            <td>07/06J2002</td>
          </tr>
           <tr>
            <td>7</td>
            <td>2102</td>
            <td>Shinumol francis</td>
            <td>12/08/1999</td>
          </tr>
           <tr>
            <td>8</td>
            <td>2103</td>
            <td>Georgekutty Mathew </td>
            <td>17/04/2003</td>
          </tr>
           <tr>
            <td>9</td>
            <td>2104</td>
            <td>Abi Mathew A</td>
            <td>21/05/1996</td>
          </tr>
           <tr>
            <td>10</td>
            <td>2105</td>
            <td>Noufia Nazar</td>
            <td>17/12/1998</td>
          </tr>
           <tr>
            <td>11</td>
            <td>2106</td>
            <td>Jinumol T john</td>
            <td>30/01/1998</td>
          </tr>
           <tr>
            <td>12</td>
            <td>2107</td>
            <td>Saji joseph</td>
            <td>14/10/1998  </td>
          </tr>
           <tr>
            <td>13</td>
            <td>2108</td>
            <td>Robin Augustine Thomas</td>
            <td>06/02/2000 </td>
          </tr>
           <tr>
            <td>14</td>
            <td>2109</td>
            <td>Tony Varky</td>
            <td>05/04/2000</td>
          </tr>
           <tr>
            <td>15</td>
            <td>2110</td>
            <td>Shilpa mary S</td>
            <td>02/09/1998</td>
          </tr>
           <tr>
            <td>16</td>
            <td>2111</td>
            <td>Mahima Mathew</td>
            <td>05/06/1999</td>
          </tr>
           <tr>
            <td>17</td>
            <td>2112</td>
            <td>Nithin Tom</td>
            <td>02/06/1999</td>
          </tr>
           <tr>
            <td>18</td>
            <td>2113 </td>
            <td>Tom Jose</td>
            <td>17/06/1998</td>
          </tr>
           <tr>
            <td>19</td>
            <td>2114</td>
            <td>Sneha Thomas</td>
            <td>19/01/1998</td>
          </tr>
            <tr>
            <td>20</td>
            <td>2115</td>
            <td>Saijo K Thomas</td>
            <td>15/07/1992</td>
          </tr>
           <tr>
            <td>21</td>
            <td>2116</td>
            <td>Abin Thomas</td>
            <td>20/01/2000</td>
          </tr>
          <tr>
            <td>22</td>
            <td>2117</td>
            <td>Elsa mol Joseph</td>
            <td>25/02/2003</td>
          </tr>
           <tr>
            <td>23</td>
            <td>2118</td>
            <td>Sandra rose Thomas	</td>
            <td>26/07/2002</td>
          </tr>
           <tr>
            <td>24</td>
            <td>2119</td>
            <td>Treema Paul	</td>
            <td>28/03/1999</td>
          </tr>
          <tr>
            <td>25</td>
            <td>2120</td>
            <td>Prince Mathew</td>
            <td>04/10/1996</td>
          </tr>
          <tr>
            <td>26</td>
            <td>2121</td>
            <td>Kochurani Shaji</td>
            <td>24/05/1999</td>
          </tr>
           <tr>
            <td>27</td>
            <td>2122</td>
            <td>Safna KS</td>
            <td>30/03/2000</td>
          </tr>
          <tr>
            <td>28</td>
            <td>2123</td>
            <td>Jeswin P Toji</td>
            <td>20/09/2001</td>
          </tr>
           <tr>
            <td>29</td>
            <td>2124</td>
            <td>Jeffin Joseph</td>
            <td>24/01/1997</td>
          </tr>
           <tr>
            <td>30</td>
            <td>2125</td>
            <td>Josmy Antony</td>
            <td>04/03/2002</td>
          </tr>
           <tr>
            <td>31</td>
            <td>2126</td>
            <td>Dona mol Raju</td>
            <td>20/04/2000</td>
          </tr>
            <tr>
            <td>32</td>
            <td>2127</td>
            <td>Savio Varghese</td>
            <td>02/05/1999</td>
          </tr>
          <tr>
            <td>33</td>
            <td>2128</td>
            <td>Josekutty Francis</td>
            <td>01/08/1995</td>
          </tr>
        
        </table>
      </v-flex>
      <v-flex pt-5 xs12 lg9 class="bold" style="font-size:20px;color:#2c3e50">
2020-2022 Second Year 
      </v-flex>
      <v-flex x12 lg9>
        <table>
          <tr>
            <th>S.No</th>
            <th>Adm.NO</th>
            <th>Name</th>
            <th>Dob</th>
          </tr>
          <tr>
            <td>1</td>
            <td>2063</td>
            <td>Roshna Joseph</td>
            <td>01/08/1998</td>
          </tr>
          <tr>
            <td>2</td>
            <td>2064</td>
            <td>Juval Marya Joseph</td>
            <td>09/05/1999</td>
          </tr>
          <tr>
            <td>3</td>
            <td>2065</td>
            <td>Amrutha mol Saji</td>
            <td>20/08/1999</td>
          </tr>
          <tr>
            <td>4</td>
            <td>2066</td>
            <td>Amina Siyad</td>
            <td>19/07/2001</td>
          </tr>
           <tr>
            <td>5</td>
            <td>2067</td>
            <td>Jinumol Reghunath</td>
            <td>08/07/1998</td>
          </tr>
           <tr>
            <td>6</td>
            <td>2068</td>
            <td>Simla Sunny</td>
            <td>28/12/1999</td>
          </tr>
           <tr>
            <td>7</td>
            <td>2069</td>
            <td>Tintu Kunjumon</td>
            <td>14/01/1998</td>
          </tr>
           <tr>
            <td>8</td>
            <td>2070</td>
            <td>Anna Maria Abraham</td>
            <td>12/08/2000</td>
          </tr>
           <tr>
            <td>9</td>
            <td>2071</td>
            <td>Ammu Thankappan.K</td>
            <td>17/09/1997</td>
          </tr>
           <tr>
            <td>10</td>
            <td>2072</td>
            <td>Sandra Thomas</td>
            <td>25/03/1998</td>
          </tr>
           <tr>
            <td>11</td>
            <td>2073</td>
            <td>Alen P mohandas</td>
            <td>17/03/1998</td>
          </tr>
           <tr>
            <td>12</td>
            <td>2074</td>
            <td>Akhila Biju</td>
            <td>20/04/1998  </td>
          </tr>
           <tr>
            <td>13</td>
            <td>2075</td>
            <td>Elsy Augustian</td>
            <td>21/12/2000 </td>
          </tr>
           <tr>
            <td>14</td>
            <td>2076</td>
            <td>Ashly Elsa Joseph</td>
            <td>14/08/2001</td>
          </tr>
           <tr>
            <td>15</td>
            <td>2077</td>
            <td>Arathy Binu</td>
            <td>22/05/2000</td>
          </tr>
           <tr>
            <td>16</td>
            <td>2078</td>
            <td>Renjumol Rajesh</td>
            <td>12/04/2001</td>
          </tr>
           <tr>
            <td>17</td>
            <td>2079</td>
            <td>Jomol Joy</td>
            <td>04/11/1998</td>
          </tr>
           <tr>
            <td>18</td>
            <td>2080</td>
            <td>Soumyamol K</td>
            <td>08/02/1995</td>
          </tr>
           <tr>
            <td>19</td>
            <td>2081</td>
            <td>Sona Abraham</td>
            <td>20/03/1997</td>
          </tr>
            <tr>
            <td>20</td>
            <td>2082</td>
            <td>Jomol KP</td>
            <td>12/08/2001</td>
          </tr>
           <tr>
            <td>21</td>
            <td>2083</td>
            <td>Albin T Reji</td>
            <td>09/01/1997</td>
          </tr>
          <tr>
            <td>22</td>
            <td>2084</td>
            <td>Sherin V Arun</td>
            <td>20/11/1997</td>
          </tr>
           <tr>
            <td>23</td>
            <td>2085</td>
            <td>Jithin P Johny	</td>
            <td>01/09/1996</td>
          </tr>
           <tr>
            <td>24</td>
            <td>2086</td>
            <td>Jomol Kochumon	</td>
            <td>27/12/2001</td>
          </tr>
          <tr>
            <td>25</td>
            <td>2087</td>
            <td>Agnus Peter</td>
            <td>21/04/2002</td>
          </tr>
           <tr>
            <td>26</td>
            <td>2088</td>
            <td>JobinRajan</td>
            <td>03/05/1997</td>
          </tr>
          <tr>
            <td>27</td>
            <td>2089</td>
            <td>Alan Varghese</td>
            <td>20/07/1996</td>
          </tr>
           <tr>
            <td>28</td>
            <td>2090</td>
            <td>Ancy Chacko</td>
            <td>30/05/1992</td>
          </tr>
           <tr>
            <td>29</td>
            <td>2091</td>
            <td>Sereena S</td>
            <td>16/06/2001</td>
          </tr>
           <tr>
            <td>30</td>
            <td>2092</td>
            <td>Sayan P Kurian</td>
            <td>03/02/2001</td>
          </tr>
            <tr>
            <td>31</td>
            <td>2093</td>
            <td>Merin Yohannan</td>
            <td>16/11/1991</td>
          </tr>
          <tr>
            <td>32</td>
            <td>2094</td>
            <td>Martin Thomas</td>
            <td>15/02/1998</td>
          </tr>
           <tr>
            <td>33</td>
            <td>2095</td>
            <td>Unnimaya Vinod</td>
            <td>14/12/1997</td>
          </tr>
        </table>
      </v-flex>

      <v-flex xs12 lg9 class="bold" style="font-size:20px;color:#2c3e50" pt-lg-6>
    2021-2023 New Students
</v-flex>
<v-flex x12 lg9>
    <div style="overflow-x: auto;">
        <table style="width: 100%; border-collapse: collapse;">
            <thead>
                <tr>
                    <th style="min-width: 50px;">No.</th>
                    <th style="min-width: 100px;">Admission Date</th>
                    <th style="min-width: 120px;">Present Status</th>
                    <th style="min-width: 120px;">Class in which Studying</th>
                    <th style="min-width: 120px;">Year of Passing Out</th>
                    <th style="min-width: 150px;">University Roll No</th>
                    <th style="min-width: 200px;">Student Name</th>
                    <th style="min-width: 100px;">DOB</th>
                    <th style="min-width: 200px;">Father Name</th>
                    <th style="min-width: 200px;">Mother Name</th>
                    <th style="min-width: 100px;">Gender</th>
                    <th style="min-width: 100px;">Caste</th>
                    <th style="min-width: 150px;">Aadhaar No.</th>
                    <th style="min-width: 150px;">Mobile Number</th>
                    <th style="min-width: 200px;">Email ID</th>
                    <th style="min-width: 100px;">Is PH/Divyang</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>12/10/2021</td>
                    <td>Pass Out</td>
                    <td>I YEAR</td>
                    <td>2021</td>
                    <td>2103432</td>
                    <td>ANNIE THOMAS</td>
                    <td>22/08/2002</td>
                    <td>T J THOMAS</td>
                    <td>REGIMOL MATHEW</td>
                    <td>FEMALE</td>
                    <td>CHRISTIAN</td>
                    <td>674028786577</td>
                    <td>9495710573</td>
                    <td>aniethomasa1822@gmail.com</td>
                    <td>No</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>12/10/2021</td>
                    <td>Pass Out</td>
                    <td>I YEAR</td>
                    <td>2021</td>
                    <td>2103433</td>
                    <td>ANJU THOMAS</td>
                    <td>04/08/1998</td>
                    <td>THOMAS MATHEW</td>
                    <td>ALICE THOMAS</td>
                    <td>FEMALE</td>
                    <td>CHRISTIAN</td>
                    <td>812149763207</td>
                    <td>9495158025</td>
                    <td>sranjuthomas98@gmail.com</td>
                    <td>No</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>12/10/2021</td>
                    <td>Pass Out</td>
                    <td>I YEAR</td>
                    <td>2021</td>
                    <td>2103434</td>
                    <td>ANN PREETI MANOJ</td>
                    <td>28/05/2003</td>
                    <td>MANOJ JOSEPH</td>
                    <td>JANE MANOJ</td>
                    <td>FEMALE</td>
                    <td>CHRISTIAN</td>
                    <td>701796069546</td>
                    <td>9605628221</td>
                    <td>annpreetimanoj077@gmail.com</td>
                    <td>No</td>
                </tr>
              
            </tbody>
        </table>
    </div>
</v-flex> -->





    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Array of button data
      buttons: [
        { label: "2020-2022 First Year", route: "/studentListDetailedView" },
        { label: "2020-2022 Second Year", route: "/studentListDetailedView" },
        { label: "2021-2023 New Students", route: "/studentListDetailedView" },
        { label: "2022-2024 First Year", route: "/studentListDetailedView" },
      ],
    };
  },
  methods: {
    // Handle button click
    handleButtonClick(route, label) {
      this.$router.push({
        path: route,
        query: { list: label }, // Pass the label as a query parameter
      });
    },
  },
};
</script>

<style>
table {
  font-family: pbold;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
  font-family: pregular;
}
tr {
  font-family: pregular;
}
.LH {
  line-height: 30px;
}
</style>